

// Accepts a color prop
const TopGradient = ({ color }: { color: string }) => {
    return (
        <svg width="208" height="212" viewBox="0 0 208 212" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute z-0 top-0 left-0">
            <path d="M207.25 182C207.25 198.154 194.154 211.25 178 211.25L30 211.25C13.8457 211.25 0.750029 198.154 0.750027 182L0.750012 30C0.75001 13.8457 13.8457 0.750014 30 0.750013L178 0.750002C194.154 0.750001 207.25 13.8457 207.25 30L207.25 182Z" stroke="url(#paint0_linear_243_13046)" stroke-opacity="0.7" stroke-width="1.5" />
            <defs>
                <linearGradient id="paint0_linear_243_13046" x1="6.48105" y1="16.9202" x2="67.3352" y2="46.1316" gradientUnits="userSpaceOnUse">
                    <stop stop-color={color} />
                    <stop offset="1" stop-color={color} stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default TopGradient;