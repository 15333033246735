// We export the tools and images here so that we can use them in other components.

export const tools = [
    {
        title: "MOTD Editor",
        href: "/motd_editor",
        image: require("./assets/images/tools/motdeditor.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        version: "3.7",
        color: "#85B079B2"
    },
    {
        title: "Coordinate Calculator",
        href: "/coord_calc",
        image: require("./assets/images/tools/coordinatecalculator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        version: "3.7",
        color: "#EBAC15B2"
    },
    {
        title: "Tick Calculator",
        href: "/tick_calc",
        image: require("./assets/images/tools/tickcalculator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        version: "3.7",
        color: "#71C418B2"
    },
    {
        title: "Skin Grabber",
        href: "/skin_grabber",
        image: require("./assets/images/tools/skingrabber.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        version: "3.7",
        color: "#EED4ABB2"
    },
    {
        title: "Circle Generator",
        href: "/circle_gen",
        image: require("./assets/images/tools/circlegenerator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        version: "3.7",
        color: "#4EC33FB2"
    },
    {
        title: "Crafting Generator",
        href: "/crafting_gen",
        image: require("./assets/images/tools/craftinggenerator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        version: "3.7",
        color: "#846B45B2"
    },
    {
        title: "Resource Calculator",
        href: "/resource_calc",
        image: require("./assets/images/tools/resourcecalculator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        version: "3.7",
        color: "#9C7C98B2"
    },
    {
        title: "NBT Editor",
        href: "/nbt_editor",
        image: require("./assets/images/tools/nbteditor.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        version: "3.7",
        color: "#B4732FB2"
    },
    {
        title: "Map Generator",
        href: "/map_generator",
        image: require("./assets/images/tools/mapgenerator.png"),
        description: "This is short text about Minecraft tools. Also placeholder text can be longer...",
        version: "3.7",
        color: "#E74C3CB2"
    },
];

// Define tool type
export type Tool = {
    title: string;
    href: string;
    image: string;
    description: string;
    color: string;
};