import React from "react";

const UpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
    </svg>
);

const DownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
    </svg>
);

const TimeField = ({ label, onChange, value }: { label: string; onChange: (v: any) => any; value: number }) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    return (
        <div className="flex flex-col w-full">
            <label className="font-semibold mr-2 w-[40%] inline-block h-fit">{label}</label>
            <div className="relative">
                <input
                    defaultValue="0"
                    value={value.toString()}
                    type="number"
                    ref={inputRef}
                    onChange={(e) => onChange(parseInt(e.target.value) ?? 0)}
                    className="rounded-md w-full px-5 py-4 bg-[#342D4E] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />

                <div className="flex flex-col absolute right-0 top-0 z-10 h-full justify-center">
                    <button
                        onClick={() => {
                            const v = parseInt(inputRef.current?.value ?? "0") + 1;
                            onChange(v);
                        }}
                        className="w-fit bg-transparent py-0 px-2"
                    >
                        <UpIcon />
                    </button>
                    <button
                        onClick={() => {
                            const v = parseInt(inputRef.current?.value ?? "0") - 1;
                            if (v < 0) return;
                            onChange(v);
                        }}
                        className="w-fit bg-transparent py-0 px-2"
                    >
                        <DownIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TimeField;
