import Colors from "./Colors";
import Styles from "./Styles";

interface Params {
    setColor: (color: string) => void;
    color: string;
    styleCallback: (style: string) => void;
    selectedStyles: string[];
}

const Toolbar = ({ setColor, color, styleCallback, selectedStyles }: Params) => {
    return (
        <div className="flex flex-col gap-6 w-full">
            <Colors setColor={setColor} selectedColor={color} />
            <Styles styleCallback={styleCallback} selectedStyles={selectedStyles} />
        </div>
    );
};

export default Toolbar;
