import React from "react";
import Modal from "./Modal";
import { getMotdFromIp, isValidMotd, motdToSlate } from "../utils";

type Props = {
    visible: boolean;
    onClose: () => void;
    maxLineWidth: number;
    motdCallback: (data: any) => void;
    fontSize: string;
};

const ImportMotdModal = ({ visible, onClose, maxLineWidth, motdCallback, fontSize }: Props) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [importError, setImportError] = React.useState("");

    const validateInput = (input: string) => {
        // Is an ip or domain
        if (
            input.match(
                /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63})$/gi
            )
        ) {
            return "ip";
        }

        const { valid, reason } = isValidMotd(input, maxLineWidth, fontSize);
        if (valid) {
            return "motd";
        } else {
            setImportError(reason);
        }
        return "invalid";
    };

    return (
        <Modal isOpen={visible} onClose={onClose} title={"Import MOTD"}>
            <input
                type="text"
                ref={inputRef}
                className="w-[95%] rounded-md border-none p-2 text-lg"
                placeholder="Enter MOTD here / A server IP"
                style={{ fontFamily: "Minecraft" }}
            />
            <p className="text-red-400 m-0">{importError}</p>

            <button
                className="tracking-widest mt-5 bg-blue-500"
                style={{ fontFamily: "Minecraft" }}
                onClick={async () => {
                    const input = (inputRef.current as HTMLInputElement | null)?.value;

                    if (!input) {
                        return;
                    }

                    let ipData: any = {};
                    const res = validateInput(input);
                    if (res !== "invalid") setImportError("");
                    let motdText = "";
                    if (res === "ip") {
                        ipData = await getMotdFromIp(input);
                        if (!ipData) {
                            setImportError("Failed to fetch MOTD");
                            return;
                        }
                        motdText = ipData.motd;
                    } else {
                        motdText = input;
                    }

                    if (res !== "invalid") {
                        const leaves = motdToSlate(motdText);

                        let serverIcon = null;
                        let serverName = null;

                        if (res === "ip") {
                            serverIcon = ipData.favicon;
                            serverName = input;
                        }

                        motdCallback({
                            leaves,
                            serverIcon,
                            serverName,
                        });
                    }
                }}
            >
                import
            </button>
            <button className="tracking-widest mt-5 bg-red-500 ml-2" style={{ fontFamily: "Minecraft" }} onClick={() => onClose()}>
                cancel
            </button>
        </Modal>
    );
};

export default ImportMotdModal;
