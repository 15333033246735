import pako from "pako";
import { Editor, Text } from "slate";
import widthGroups from "./data/char_size_groups";
import widthGroupsBold from "./data/char_size_groups_bold";
import { Node, Element } from "slate";

const widthGroupsCache = new Map();
interface GetTextWidthFunction {
    (txt: string, fontname: string, fontsize: string | number, weight?: number): number;
    c?: HTMLCanvasElement;
    ctx?: CanvasRenderingContext2D;
}

const getTextWidth: GetTextWidthFunction = (txt, fontname, fontsize, weight = 400): number => {
    let fontspec = weight + " " + fontsize + " " + fontname;

    if (!getTextWidth.c) {
        getTextWidth.c = document.createElement("canvas");
        getTextWidth.ctx = getTextWidth.c.getContext("2d") as CanvasRenderingContext2D;
    }
    if (getTextWidth.ctx && getTextWidth.ctx.font !== fontspec) {
        getTextWidth.ctx.font = fontspec;
    }
    const w = getTextWidth.ctx?.measureText(txt).width || 0;
    console.log(fontspec, txt, w);
    return w;
};

const styleCodes: { [key: string]: string } = {
    "#000000": "\\u00A70",
    "#0000AA": "\\u00A71",
    "#00AA00": "\\u00A72",
    "#00AAAA": "\\u00A73",
    "#AA0000": "\\u00A74",
    "#AA00AA": "\\u00A75",
    "#FFAA00": "\\u00A76",
    "#AAAAAA": "\\u00A77",
    "#555555": "\\u00A78",
    "#5555FF": "\\u00A79",
    "#55FF55": "\\u00A7a",
    "#55FFFF": "\\u00A7b",
    "#FF5555": "\\u00A7c",
    "#FF55FF": "\\u00A7d",
    "#FFFF55": "\\u00A7e",
    "#FFFFFF": "\\u00A7f",
    obf: "\\u00A7k",
    bold: "\\u00A7l",
    strikethrough: "\\u00A7m",
    underline: "\\u00A7n",
    italic: "\\u00A7o",
    reset: "\\u00A7r",
};

const reversedStyleCodes = Object.fromEntries(Object.entries(styleCodes).map(([key, value]) => ["§" + value.slice(value.length - 1), key]));

type LeafElement = {
    text: string;
    color?: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    strike?: boolean;
    obf?: boolean;
    [key: string]: string | boolean | undefined;
};

type BlockElement = {
    alignment: string;
    type: string;
    children: LeafElement[];
};

const getClosestColor = (colorList: string[], targetColor: string) => {
    const hexToRgb = (hex: string) => {
        const match = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16));
        if (!match) return [0, 0, 0];
        return [match[0], match[1], match[2]];
    };
    const dist = (a: number[], b: number[]) => Math.hypot(...a.map((x, i) => x - b[i]));
    const targetRgb = hexToRgb(targetColor);
    return colorList.reduce((closest, color) => (dist(hexToRgb(color), targetRgb) < dist(hexToRgb(closest), targetRgb) ? color : closest));
};

const verifyElement = (element: string) => {
    const color = element.slice(1);
    if (color.startsWith("#")) {
        const style =
            styleCodes[
                getClosestColor(
                    Object.keys(styleCodes).filter((x) => x.startsWith("#")),
                    color
                )
            ];
        return "§" + style.slice(style.length - 1);
    }
    return element;
};

const getLeavesFromMotdLine = (line: string) => {
    if (!line) return [];
    const leaves = [];
    const regex = /§[0-9a-fl-ork]|§#......|./g;
    const elements: string[] = Array.from(line.matchAll(regex), (match) => match[0]);

    let currLeaf: Record<string, any> = { text: "", color: "#AAAAAA", bold: false, italic: false, underline: false, strike: false, obf: false };

    for (let element of elements) {
        element = verifyElement(element);

        if (reversedStyleCodes[element]) {
            if (currLeaf.text.length > 0) {
                leaves.push(JSON.parse(JSON.stringify(currLeaf)));
            }

            if (element === "§r" || reversedStyleCodes[element].startsWith("#")) {
                currLeaf = { text: "", color: "#AAAAAA", bold: false, italic: false, underline: false, strike: false, obf: false };
            }

            if (element !== "§r") {
                currLeaf.text = "";
                if (reversedStyleCodes[element].startsWith("#")) {
                    currLeaf.color = reversedStyleCodes[element];
                } else {
                    currLeaf[reversedStyleCodes[element]] = true;
                }
            }
            continue;
        }

        currLeaf.text += element;
    }
    if (currLeaf.text.length > 0) {
        leaves.push(currLeaf);
    }

    return leaves;
};

const motdToSlate = (motd: string) => {
    const lines = motd.split("\\n");
    const firstLineLeaves = getLeavesFromMotdLine(lines[0]);
    const paras = [{ type: "paragraph", children: firstLineLeaves.length ? firstLineLeaves : [{ type: "text", text: "" }] }];

    const secondLineLeaves = getLeavesFromMotdLine(lines[1]);
    if (secondLineLeaves.length) {
        paras.push({
            type: "paragraph",
            children: secondLineLeaves,
        });
    }
    return paras;
};

const isValidMotd = (motd: string, maxLineWidth: number, fontSize: string) => {
    if (motd.split("\\n").length > 2) {
        return { valid: false, reason: "Too many lines" };
    }

    const firstLineLeaves = getLeavesFromMotdLine(motd.split("\\n")[0]);
    const secondLineLeaves = getLeavesFromMotdLine(motd.split("\\n").length === 1 ? "" : motd.split("\\n")[1]);

    let firstLineWidth = 0;
    for (const leaf of firstLineLeaves) {
        firstLineWidth += getTextWidth(leaf.text, "minecraftFont", fontSize, leaf.bold ? 800 : 400);
    }

    let secondLineWidth = 0;
    for (const leaf of secondLineLeaves) {
        secondLineWidth += getTextWidth(leaf.text, "minecraftFont", fontSize, leaf.bold ? 800 : 400);
    }

    if (firstLineWidth > maxLineWidth) return { valid: false, reason: "First line too long" };

    if (secondLineWidth > maxLineWidth) return { valid: false, reason: "Second line too long" };

    return { valid: true, reason: "" };
};

const getAllLeaves = (editor: Editor) => {
    const leaves = [];

    for (const [node] of Editor.nodes(editor, {
        at: [],
        match: Text.isText,
        mode: "lowest",
    })) {
        leaves.push(node);
    }

    return leaves;
};

const unmergeLeaves = (leaves: LeafElement[]) => {
    const newLeaves = [];
    for (let leaf of leaves) {
        for (let char of leaf.text) {
            newLeaves.push({ ...leaf, text: char });
        }
    }
    return newLeaves;
};

const getMotdFromIp = async (ip: string) => {
    try {
        const res = await fetch(`https://wisehosting.coreware.nl/api/motd/${ip}`);
        const data = await res.json();

        return data;
    } catch (e) {
        return null;
    }
};

const getBlocks = (motdString: string) => {
    motdString = motdString.replace("\n", "\\n");
    const regex = /§[0-9a-fl-or]|§#......|\\n|./g;
    const elements = Array.from(motdString.matchAll(regex), (match) => match[0]);
    const blocks = [];
    let blockIndex = -1;
    for (const element of elements) {
        if (element === "§r") {
            blockIndex++;
            blocks[blockIndex] = [element];
            continue;
        }
        if (blocks[blockIndex]) {
            blocks[blockIndex].push(element);
        }
    }
    return blocks;
};

const doStylesHaveColor = (styles: string[]) => {
    return styles.some((style) => style.match(/§[a-f0-9]/gi));
};

const getColorFromStyles = (styles: string[]) => {
    return styles.find((style) => style.match(/§[a-f0-9]/gi));
};

const decodeMotdId = (encoded: string) => {
    let urlDecoded = decodeURIComponent(encoded);
    let base64Decoded = atob(urlDecoded);
    let uint8Array = new Uint8Array(base64Decoded.split("").map((char) => char.charCodeAt(0)));
    let inflated = pako.inflateRaw(uint8Array);
    return new TextDecoder().decode(inflated);
};

const getLeaves = (data: any[]) => {
    return [data.length >= 1 ? data[0]?.children ?? [] : [], data.length === 2 ? data[1]?.children ?? [] : []];
};

const convertLeafToMinecraft = (leaf: LeafElement) => {
    let styleString = styleCodes.reset;
    for (const style in leaf) {
        if (!style || !leaf[style]) continue;

        if (styleCodes[style] || style === "color") {
            styleString += styleCodes[style === "color" ? leaf[style] ?? style : style] ?? "";
        }
    }

    styleString += leaf.text;

    return styleString;
};

const applyAlignmentLine = (line: LeafElement[], motdLine: string, alignment: string, fontSize: string, maxLineWidth: number) => {
    if (alignment === "left") {
        return motdLine;
    } else {
        const lineWidth = line.reduce((acc, leaf) => {
            return acc + getTextWidth(leaf.text, "minecraftFont", fontSize, leaf.bold ? 800 : 400);
        }, 0);

        const spaceCharWidth = getTextWidth(" ", "minecraftFont", fontSize, 400);
        const spaceCount = Math.ceil((maxLineWidth - lineWidth) / spaceCharWidth);
        if (alignment === "center") {
            return " ".repeat(spaceCount / 2) + motdLine;
        } else if (alignment === "right") {
            return " ".repeat(spaceCount) + motdLine;
        }
    }
};

const applyAlignment = (motdLines: string[], data: BlockElement[], fontSize: string, maxLineWidth: number) => {
    const newMotdLines = [];
    const lineLeaves = getLeaves(data);

    for (let i = 0; i < motdLines.length; i++) {
        if (!motdLines[i]) continue;
        newMotdLines.push(applyAlignmentLine(lineLeaves[i], motdLines[i], data[i].alignment ?? "left", fontSize, maxLineWidth));
    }
    return newMotdLines;
};

const exportMotd = (data: BlockElement[], fontSize: string, maxLineWidth: number) => {
    const motdLines = [];
    const lineLeaves = getLeaves(data);
    for (const line of lineLeaves) {
        let motdString = "";
        for (const leaf of line) {
            motdString += convertLeafToMinecraft(leaf);
        }
        motdLines.push(motdString);
    }
    const newMotdLines = applyAlignment(motdLines, data, fontSize, maxLineWidth);
    newMotdLines[0] = styleCodes.reset + newMotdLines[0];
    if (newMotdLines.length > 1) newMotdLines[1] = styleCodes.reset + newMotdLines[1];
    return newMotdLines;
};

const optimizeMotdString = (motdString: string) => {
    motdString = motdString.replace(/\\u00a7/gi, "§");

    // Remove redundant resets
    const blocks = getBlocks(motdString);
    let lastBlockStyles: string[] = [];
    const newBlocks = [];
    for (let block of blocks) {
        let currBlockStyles = block.filter((b) => b.startsWith("§"));
        const currBlockText = block.filter((b) => !b.startsWith("§"));
        const isTextVisible = currBlockText.some((text) => text.trim().length > 0);
        if (!isTextVisible) {
            currBlockStyles = [];
            block = currBlockText;
        }

        // Check if some last block styles are not present in current block styles, and make sure that colors are not reset between blocks
        const previousBlockStyles = [...lastBlockStyles];
        const shouldResetBetweenBlocks = previousBlockStyles.some(
            (style) => !currBlockStyles.includes(style) && !(doStylesHaveColor(currBlockStyles) && doStylesHaveColor(previousBlockStyles))
        );
        if (!shouldResetBetweenBlocks && newBlocks.length > 0) {
            block.shift();
        }
        newBlocks.push(block);
        lastBlockStyles = currBlockStyles;
        lastBlockStyles.push("§r");
    }

    // Compress styles
    lastBlockStyles = [];
    let blockIndex = 0;
    for (let i = 0; i < newBlocks.length; i++) {
        let block = newBlocks[i];
        if (block.length === 0) continue;

        const currBlockStyles = block.filter((b) => b.startsWith("§"));
        let stylesToRemove = lastBlockStyles.filter((style) => currBlockStyles.includes(style));
        if (currBlockStyles.includes("§r")) {
            stylesToRemove = [];
        }

        let foundStarterColor = false;
        if (blockIndex === 0 && getColorFromStyles(currBlockStyles) === "§7") {
            stylesToRemove.push("§7");
            foundStarterColor = true;
        }

        if (!doStylesHaveColor(currBlockStyles) || foundStarterColor) {
            for (const style of stylesToRemove) {
                block = block.splice(block.indexOf(style), 1);
            }
        }

        lastBlockStyles = currBlockStyles;
        blocks[i] = block;
        blockIndex++;
    }

    motdString = newBlocks.flat().join("");

    // Last cleanup
    if (motdString.trimStart().length !== motdString.length) {
        motdString = "§r" + motdString;
    }

    return motdString;
};

const getCharGroupIndex = (char: string) => {
    for (let i = 0; i < widthGroups.length; i++) {
        if (widthGroups[i].includes(char)) {
            return i;
        }
    }
};

const getRandomCharFromGroup = (groupIndex: number | undefined, bold: boolean) => {
    if (groupIndex === undefined) {
        return "a";
    }
    if (bold) {
        return widthGroupsBold[groupIndex][Math.floor(Math.random() * widthGroupsBold[groupIndex].length)];
    }
    return widthGroups[groupIndex][Math.floor(Math.random() * widthGroups[groupIndex].length)];
};

const getRandomCharsUniformWidth = (textToRandomize: string, bold: boolean) => {
    let randomText = "";

    for (let i = 0; i < textToRandomize.length; i++) {
        const char = textToRandomize[i];
        if (widthGroupsCache.has(char + (bold ? 1 : 0))) {
            randomText += getRandomCharFromGroup(widthGroupsCache.get(char + (bold ? 1 : 0)), bold);
        } else {
            const groupIndex = getCharGroupIndex(char);
            if (Object.keys(widthGroupsCache).length < 200) {
                widthGroupsCache.set(char + (bold ? 1 : 0), groupIndex);
            }
            randomText += getRandomCharFromGroup(groupIndex, bold);
        }
    }

    return randomText;
};

const getAbsoluteOffset = (editor: Editor) => {
    const { selection } = editor;

    if (!selection) {
        return 0; // No selection
    }

    const { anchor } = selection;
    const { path, offset } = anchor;

    let absoluteOffset = offset;

    for (let i = 0; i < path.length; i++) {
        const subPath = path.slice(0, i);
        const node = Node.get(editor, subPath);

        for (let j = 0; j < path[i]; j++) {
            const siblingNode = (node as Element).children[j];
            absoluteOffset += Node.string(siblingNode).length;
        }
    }

    return absoluteOffset;
};

const getPathFromAbsoluteOffset = (editor: Editor, absoluteOffset: number) => {
    let remainingOffset = absoluteOffset;
    let path: number[] = [];

    const traverse = (node: LeafElement | BlockElement | Editor, currentPath: number[]) => {
        if (Text.isText(node)) {
            if (remainingOffset <= node.text.length) {
                path = currentPath;
                return true;
            }
            remainingOffset -= node.text.length;
        } else {
            for (let i = 0; i < node.children.length; i++) {
                const childPath = currentPath.concat(i);
                if (traverse((node as BlockElement).children[i], childPath)) {
                    return true;
                }
            }
        }
        return false;
    };

    traverse(editor, []);

    return {
        path,
        offset: remainingOffset,
    };
};

export {
    getTextWidth,
    styleCodes,
    reversedStyleCodes,
    getClosestColor,
    verifyElement,
    motdToSlate,
    getLeavesFromMotdLine,
    isValidMotd,
    getAllLeaves,
    unmergeLeaves,
    getMotdFromIp,
    getBlocks,
    doStylesHaveColor,
    getColorFromStyles,
    decodeMotdId,
    getLeaves,
    exportMotd,
    optimizeMotdString,
    getRandomCharsUniformWidth,
    getAbsoluteOffset,
    getPathFromAbsoluteOffset,
};

export type { LeafElement, BlockElement };
