import React, { useState, useEffect } from "react";

interface Params {
    icon?: React.FC;
    text: string;
    action: () => void;
    copiedText: string;
    filled?: boolean;
}

const AnimatedButton = ({ icon: Icon, text, action, copiedText, filled }: Params) => {
    const [showCopied, setShowCopied] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (showCopied) {
            timer = setTimeout(() => setShowCopied(false), 600);
        }
        return () => clearTimeout(timer);
    }, [showCopied]);

    const handleClick = () => {
        action();
        setShowCopied(true);
    };

    return (
        <div
            className={
                "border-[#67598D] border-[1px] flex items-center gap-2 cursor-pointer rounded-xl px-6 py-1 justify-center relative overflow-hidden" +
                (filled ? " bg-[#00B8FF] " : "") +
                (showCopied ? " bg-purple-900" : "")
            }
            style={{ transition: "background-color 0.3s ease" }}
            onClick={handleClick}
        >
            <div className={filled ? "text-black" : ""} hidden={showCopied}>
                {Icon && <Icon />}
            </div>
            <span className={"text-sm font-semibold" + (filled && " text-black ") + (showCopied && " text-transparent")}>{text}</span>
            {showCopied && (
                <div
                    className={
                        "absolute inset-0 flex items-center justify-center bg-opacity-90 text-sm font-semibold bg-purple-950 rounded-sm " +
                        (filled ? " text-black " : " text-white")
                    }
                    style={{
                        animation: "fadeInOut 0.7s ease-in-out",
                    }}
                >
                    {copiedText}
                </div>
            )}
            <style>{`
                @keyframes fadeInOut {
                    0% {
                        opacity: 0;
                    }
                    20% {
                        opacity: 1;
                    }
                    80% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            `}</style>
        </div>
    );
};

export default AnimatedButton;
