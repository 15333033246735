import { useCallback, useEffect, useState } from "react";
import { createEditor, Editor } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import serverBackground from "../assets/images/server_bg.png";
import ServerIconUploader from "./ServerIconUploader";
import { BlockElement, getRandomCharsUniformWidth, LeafElement } from "../utils";

interface Params {
    data: any;
    serverIcon: string;
    setServerIcon: React.Dispatch<React.SetStateAction<string>>;
    serverName: string;
    maxLineWidth: number;
    fontSize: string;
}
const MotdPreview = ({ data, serverIcon, setServerIcon, serverName, maxLineWidth, fontSize }: Params) => {
    const [editor] = useState(() => withReact(createEditor()));

    const styles = {
        preview: {
            fontFamily: "minecraftFont",
            whiteSpace: "pre",
            width: maxLineWidth - 100,
            marginLeft: "8px",
            marginTop: "0.1vw",
            userSelect: "none" as const,
        },
        element: {
            lineHeight: "1.2vw",
            fontSize: fontSize,
            verticalAlign: "top",
        },
        serverName: {
            fontFamily: "minecraftFont",
            fontSize: fontSize,
        },
    };

    const processColor = (leaf: LeafElement, baseElement: any) => {
        if (leaf.color) {
            baseElement.props.style.color = leaf.color;
        }
    };

    const processStyles = (leaf: LeafElement, baseElement: any) => {
        let textDecor = "";

        if (leaf.obf) {
            baseElement.props.style.display = "inline-block";
            baseElement.props.style.overflow = "hidden";
            baseElement.props.style.verticalAlign = "top";
        }

        if (leaf.bold) {
            baseElement.props.style.fontWeight = "bold";
        }

        if (leaf.italic) {
            baseElement.props.style.fontStyle = "italic";
        }

        if (leaf.underline) {
            textDecor += "underline ";
        }

        if (leaf.strike) {
            textDecor += "line-through";
        }

        if (textDecor) {
            baseElement.props.style.textDecoration = textDecor;
        }
    };
    const Leaf = ({ attributes, children, leaf }: { attributes: any; children: HTMLElement[]; leaf: LeafElement }) => {
        let baseElement = (
            <span {...attributes} style={{ ...styles.element }} className={leaf.obf ? "obf" : ""} data-text={leaf.text}>
                {children}
            </span>
        );

        processColor(leaf, baseElement);
        processStyles(leaf, baseElement);

        return baseElement;
    };

    const Element = ({ attributes, children, element }: { attributes: any; children: HTMLElement[]; element: BlockElement }) => {
        let baseElement = (
            <p
                {...attributes}
                style={{
                    ...styles.element,
                    textAlign: element.alignment,
                }}
            >
                {children}
            </p>
        );

        return baseElement;
    };
    const renderLeaf = useCallback((props: any) => {
        return <Leaf {...props} />;
    }, []);

    const renderElement = useCallback((props: any) => {
        return <Element {...props} />;
    }, []);

    const forceRender = (editor: Editor) => {
        editor.children = data;
        editor.onChange();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const obfLeaves = document.querySelectorAll(".obf") as NodeListOf<HTMLElement>;

            for (const obfLeaf of obfLeaves) {
                if (obfLeaf.dataset.text === "" || obfLeaf.innerText === "") continue;
                const isBold = obfLeaf.style.fontWeight === "bold";
                const children = obfLeaf.childNodes as NodeListOf<HTMLElement>;
                for (const child of children) {
                    child.innerText = getRandomCharsUniformWidth(obfLeaf.dataset.text || child.innerText, isBold);
                }
            }
        }, 10);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        forceRender(editor);
    }, [data]);

    return (
        <div className="rounded-2xl overflow-hidden w-full h-fit rounded-t-none border-[#67598D] border-[1px] border-t-0">
            <Slate editor={editor} initialValue={data}>
                <div className="relative w-[100%]">
                    <img src={serverBackground} alt="" className="w-full h-full" />
                    <div className="absolute top-0 left-0 w-full h-full flex flex-row items-center">
                        <ServerIconUploader serverIcon={serverIcon} setServerIcon={setServerIcon} />
                        <div className="flex flex-col w-[80%] h-full">
                            <p
                                className="w-fit ml-2 mt-3"
                                id="serverName"
                                style={{ ...styles.serverName }}
                                contentEditable
                                suppressContentEditableWarning={true}
                            >
                                {serverName}
                            </p>
                            <Editable readOnly renderLeaf={renderLeaf} id={"preview"} renderElement={renderElement} style={{ ...styles.preview }} />
                        </div>
                    </div>
                </div>
            </Slate>
        </div>
    );
};

export default MotdPreview;
