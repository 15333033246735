import styled, { css } from "styled-components";
import tw from "twin.macro";

interface Props {
    isLoading?: boolean;
    size?: "xsmall" | "small" | "large" | "xlarge";
    color?: "green" | "red" | "primary" | "grey";
    isSecondary?: boolean;
    isWithIcon?: boolean;
    isGradient?: boolean;
}

const ButtonStyle = styled.button<Omit<Props, "isLoading">>`
    ${tw`relative inline-block rounded px-5 py-2 uppercase tracking-wide text-sm transition-all duration-150 border font-medium`};
    border-radius: 10px;

    ${(props) =>
        ((!props.isSecondary && !props.color) || props.color === "primary") &&
        css<Props>`
            ${(props) => !props.isSecondary && tw`bg-transparent`};
            color: var(--primary);

            &:hover:not(:disabled) {
                color: #fff;
                background-color: rgba(0, 183, 255, 0.2);
            }
        `};

    ${(props) =>
        props.color === "grey" &&
        css`
            ${tw`border-neutral-600 bg-neutral-500 text-neutral-50`};

            &:hover:not(:disabled) {
                ${tw`bg-neutral-600 border-neutral-700`};
            }
        `};

    ${(props) =>
        props.color === "green" &&
        css<Props>`
            ${tw`border-green-600 bg-green-500 text-green-50`};

            &:hover:not(:disabled) {
                ${tw`bg-green-600 border-green-700`};
            }

            ${(props) =>
                props.isSecondary &&
                css`
                    &:active:not(:disabled) {
                        ${tw`bg-green-600 border-green-700`};
                    }
                `};
        `};

    ${(props) =>
        props.color === "red" &&
        css<Props>`
            ${tw`border-red-600 bg-red-500 text-red-50`};

            &:hover:not(:disabled) {
                ${tw`bg-red-600 border-red-700`};
            }

            ${(props) =>
                props.isSecondary &&
                css`
                    &:active:not(:disabled) {
                        ${tw`bg-red-600 border-red-700`};
                    }
                `};
        `};

    ${(props) => props.size === "xsmall" && tw`px-2 py-1 text-xs`};
    ${(props) => (!props.size || props.size === "small") && tw`px-4 py-2`};
    ${(props) => props.size === "large" && tw`p-4 text-sm`};
    ${(props) => props.size === "xlarge" && tw`p-4 w-full`};

    ${(props) =>
        props.isSecondary &&
        css<Props>`
            ${tw`border-neutral-600 bg-transparent text-neutral-200`};
            border-width: 3px;

            &:hover:not(:disabled) {
                ${tw`border-neutral-500 text-neutral-100`};
                ${(props) => props.color === "red" && tw`bg-red-500 border-red-600 text-red-50`};
                ${(props) => props.color === "primary" && tw`bg-primary-500 border-primary-600 text-primary-50`};
                ${(props) => props.color === "green" && tw`bg-green-500 border-green-600 text-green-50`};
            }
        `};
    ${(props) =>
        props.isWithIcon &&
        css<Props>`
            ${tw`bg-transparent text-darkgray border-transparent rounded-full relative`};
            height: 35px;

            ${(props) => props.color === "red" && tw`bg-darkred`};
            ${(props) => props.color === "primary" && tw`bg-darkblue`};
            ${(props) => props.color === "green" && tw`bg-darkgreen`};

            &:hover:not(:disabled) {
                ${tw`bg-transparent text-lightgray border-transparent`};
                ${(props) =>
                    props.color === "red" &&
                    `
                    background: #B94657;
                    color: #FFB5B9;
                `}
                ${(props) =>
                    props.color === "primary" &&
                    `
                    background: #255783;
                    ${tw`text-lightblue`}
                `}
                ${(props) => props.color === "green" && tw`bg-darkgreen/50 text-green-50`};
            }

            span {
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);

                ${(props) => props.color === "red" && tw`text-lightred`};
                ${(props) => props.color === "primary" && tw`text-lightblue`};
                ${(props) => props.color === "green" && tw`text-lightgreen`};
            }
        `};
    ${(props) =>
        props.isGradient &&
        css<Props>`
            ${tw`bg-transparent text-darkgray border-transparent relative`};
            background: var(--Gradient-Blue, linear-gradient(90deg, #00b8ff 38.02%, #0ad3c5 100%));
            color: #0a0034;
            text-transform: initial;
            /* 18 SB */
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            padding: 0 35px !important;
            border-radius: 10px !important;
            height: 60px;
            &:hover:not(:disabled) {
                background: linear-gradient(90deg, #69d4fe 38.02%, #0bf6e6 100%);
                color: #0a0034;
            }
        `};

    &:disabled {
        opacity: 0.55;
        cursor: default;
    }
`;

type ComponentProps = Omit<JSX.IntrinsicElements["button"], "ref" | keyof Props> & Props;

const Button: React.FC<ComponentProps> = ({ children, isLoading, ...props }) => (
    <ButtonStyle {...props}>
        <span className={isLoading ? `text-transparent` : undefined}>{children}</span>
    </ButtonStyle>
);

export default Button;
