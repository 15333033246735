import React from "react";

interface Params {
    onClick: () => void;
    label?: string;
    cIcon?: React.JSX.Element;
    style: React.CSSProperties;
    selected?: boolean;
}

const StyleOption = ({ onClick, label, cIcon, style, selected = false }: Params) => {
    if (cIcon) {
        cIcon = React.cloneElement(cIcon, {
            color: selected ? "#20193A" : "#A39BBA",
        });
    }

    return (
        <div
            onClick={onClick}
            className="w-full text-center rounded-md text-gray-700 h-full flex justify-center items-center cursor-pointer"
            style={{
                ...style,
                backgroundColor: selected ? "#A39BBA" : "transparent",
                color: selected ? "#20193A" : "#A39BBA",
            }}
            onMouseDown={(e) => e.preventDefault()}
        >
            {label ?? (cIcon && <>{cIcon}</>)}
        </div>
    );
};

export default StyleOption;
