import ToolButton from "./components/ToolButton";
import TopGradient from "./components/TopGradient";
import { tools, Tool } from "./tools";
import BackgroundImage from "./assets/images/background.png";
import ToolboxImage from "./assets/images/toolbox.png";

const Homepage = () => {
    return (
        <div>
            <img src={BackgroundImage} alt="Background" className="fixed top-0 left-0 w-full h-full object-cover z-[-1]" />
            <div className="grid lg:grid-cols-5 p-10 px-14">
                <div className="pt-20 w-full md:w-3/4 lg:w-4/5 col-span-2 pl-10">
                    <div className="flex gap-2 items-center">
                        <img src={ToolboxImage} alt="Toolbox" className="h-10" />
                        <h1 className="text-white text-lg">Everything Minecraft, <strong>All in One Place!</strong></h1>
                    </div>
                    <h1 className="text-6xl font-bold mt-4">
                        <span className="text-lightblue">Master Minecraft</span><br /> with the Ultimate Toolbox!
                    </h1>
                    <p className="mt-20">Stay tuned for upcoming tools</p>
                    <div className="bg-gradient-to-l from-[#00B8FF80] to-[#0AD3C580] w-[fit-content] p-[1px] rounded-2xl mt-4 mb-14">
                        <div className="flex gap-4 bg-[#162540] items-center rounded-2xl">
                            <div className="pl-4">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z" stroke="white" stroke-opacity="0.6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="white" stroke-opacity="0.6" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <input type="email" placeholder="Enter email address" className="bg-transparent placeholder-[#FFFFFF99] p-2 pr-8" />
                            <button className="bg-[#00B8FF] rounded-xl m-1 w-12 h-12 flex justify-center items-center p-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
                                    <path d="M22 2L11 13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <a className="text-white font-semibold text-xl" href="https://wisehosting.com/">
                        Tools developed by
                        <img src="https://wisehosting.com/templates/wisehosting/images/logo.png" className="inline-block h-6 ml-2" alt="WiseHosting" />
                    </a>
                    <p className="mt-4">Premium <a href="https://wisehosting.com" className="text-lightblue underline">Minecraft hosting</a> at affordable prices</p>
                </div>
                <div className="flex flex-wrap col-span-3 gap-6 p-10 w-fit justify-self-end justify-items-end justify-center lg:justify-end">
                    {tools && tools.map((tool, index) => (
                        <a className="bg-[#100B22] bg-opacity-80 relative rounded-[30px] p-6 overflow-hidden w-[276px] h-[276px]" key={index} href={tool.href}>
                            <img src={tool.image} alt={tool.title} className="w-36 h-32 right-2 top-0 absolute -z-10 blur-lg" />
                            <TopGradient color={tool.color} />
                            <div className="flex flex-col justify-between h-full">
                                <div className="flex items-center gap-3">
                                    <img src={tool.image} alt={tool.title} className="h-10" />
                                    <a className="text-white" href={tool.href}>{tool.title}</a>
                                </div>
                                <p className="text-[#ACA9B4] pt-6">{tool.description}</p>
                                <br />
                                <p className="text-[#D1CCE0] pt-2">V{tool.version}</p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Homepage;
