import React from "react";
import RichMotdEditor from "./RichMotdEditor";
import Toolbar from "./Toolbar";

interface Props {
    setData: React.Dispatch<React.SetStateAction<any>>;
    data: any;
    maxLineWidth: number;
    setServerName: React.Dispatch<React.SetStateAction<string>>;
    setServerIcon: React.Dispatch<React.SetStateAction<string>>;
    setReloadChildren: React.Dispatch<React.SetStateAction<number>>;
    reloadChildren: number;
    fontSize: string;
}

const MotdEditor = ({ setData, data, maxLineWidth, reloadChildren, fontSize }: Props) => {
    const [color, setColor] = React.useState("#AAAAAA");
    const [selectedStyles, setSelectedStyles] = React.useState<string[]>([]);
    const [alignment, setAlignment] = React.useState("left");
    const [currentStyle, setCurrentStyle] = React.useState<{ style: string; newState: boolean } | null>(null);

    const styleCallback = (style: string) => {
        let styles = [...selectedStyles];

        if (["right", "center", "left"].includes(style)) {
            setAlignment(style);
            return;
        }

        setCurrentStyle({
            style,
            newState: styles.includes(style) ? false : true,
        });

        if (styles.includes(style)) {
            styles = styles.filter((s) => s !== style);
        } else {
            styles = [...styles, style];
        }

        setSelectedStyles(styles);
    };

    return (
        <div className="rounded-md rounded-b-none h-fit w-[100%] flex flex-col items-center border-[#67598D] border-[1px] gap-10 p-5">
            <Toolbar setColor={setColor} color={color} styleCallback={styleCallback} selectedStyles={[...selectedStyles, alignment]} />
            <RichMotdEditor
                setData={setData}
                currentColor={color}
                alignment={alignment}
                selectedStyles={selectedStyles}
                setColor={setColor}
                setStyles={setSelectedStyles}
                reloadChildren={reloadChildren}
                maxLineWidth={maxLineWidth}
                initialValue={data}
                setAlignment={setAlignment}
                currentStyle={currentStyle}
                setCurrentStyle={setCurrentStyle}
                fontSize={fontSize}
            />
        </div>
    );
};

export default MotdEditor;
