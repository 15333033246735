import React, { useEffect } from "react";
import MotdEditor from "./components/MotdEditor";
import MotdPreview from "./components/MotdPreview";
import defaultServerIcon from "./assets/images/server_icon.png";
import pako from "pako";
import { BlockElement, decodeMotdId, exportMotd, getTextWidth, motdToSlate, optimizeMotdString } from "./utils";
import Actions from "./components/Actions";
import ImportMotdModal from "./components/ImportMotdModal";

const getFontSize = () => {
    return window.innerWidth >= 1200 ? "0.9vw" : window.innerWidth >= 640 ? "1.36vw" : "2vw";
};

interface MotdCallbackData {
    leaves: BlockElement[];
    serverIcon: string;
    serverName: string;
}

const App = () => {
    const [fontSize, setFontSize] = React.useState(getFontSize());
    const [importModalVisible, setImportModalVisible] = React.useState(false);
    const [motdData, setMotdData] = React.useState([
        {
            type: "paragraph",
            children: [
                {
                    type: "text",
                    text: "",
                    color: "#AAAAAA",
                },
            ],
        },
    ]);

    useEffect(() => {
        const handleResize = () => {
            const fSize = getFontSize();
            setMaxLineWidth(getTextWidth("a".repeat(45), "minecraftFont", fSize));
            setFontSize(fSize);
        };
        window.addEventListener("resize", handleResize);
        setTimeout(() => handleResize(), 100);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [maxLineWidth, setMaxLineWidth] = React.useState(0);
    const [serverIcon, setServerIcon] = React.useState(defaultServerIcon);
    const [serverName, setServerName] = React.useState("WiseHosting Server");
    const [reloadChildren, setReloadChildren] = React.useState(0);

    useEffect(() => {
        getTextWidth("a".repeat(45), "minecraftFont", fontSize, 800); // Needs initialization for some reason for weight 800

        const id = new URLSearchParams(window.location.search).get("id")?.replace(/ /g, "+");
        if (id != null) {
            try {
                let motdString = decodeMotdId(id);
                motdString = motdString.replaceAll("\\u00A7", "§");
                setMotdData(motdToSlate(motdString));
                setReloadChildren((p) => p + 1);
            } catch (e) {
                alert("Couldn't load MOTD from ID");
                console.error(e);
                let url = new URL(window.location.href);
                let params = new URLSearchParams(url.search);
                params.delete("id");
                url.search = params.toString();
                window.history.pushState({}, "", url);
            }
        }
    }, []);

    const exportMotdToShareID = (data: BlockElement[]) => {
        let motdString = exportMotd(data, fontSize, maxLineWidth).join("\n");
        motdString = optimizeMotdString(motdString);
        const compressed = pako.deflateRaw(motdString, { level: 9 });
        let encoded = btoa(String.fromCharCode.apply(null, Array.from(compressed))).replace(/=/g, "");
        encoded = encodeURIComponent(encoded);
        return encoded;
    };

    const actionCallback = (action: string) => {
        if (action === "copy") {
            const exportedMotd = exportMotd(motdData as any, fontSize, maxLineWidth);
            const optimizedMotd = optimizeMotdString(exportedMotd.join("\n"));
            navigator.clipboard.writeText(optimizedMotd.replaceAll("§", "\\u00a7"));
        } else if (action === "import") {
            setImportModalVisible(true);
        } else if (action === "share") {
            const shareID = exportMotdToShareID(motdData as any);
            const href = window.location.href.replace(/\?id=.*/, "");
            navigator.clipboard.writeText(href + "?id=" + shareID);
        }
    };

    return (
        <div className="m-10 flex flex-col bg-[#20193A] p-7 w-[40%] rounded-2xl">
            <ImportMotdModal
                visible={importModalVisible}
                fontSize={fontSize}
                maxLineWidth={maxLineWidth}
                motdCallback={(data: MotdCallbackData) => {
                    setMotdData(data.leaves as any);
                    if (data.serverIcon) setServerIcon(data.serverIcon);
                    if (data.serverName) setServerName(data.serverName);
                    setReloadChildren((p) => p + 1);
                    setImportModalVisible(false);
                }}
                onClose={() => setImportModalVisible(false)}
            />
            <p className="font-bold mb-3">MOTD Editor</p>
            <MotdEditor
                setData={setMotdData}
                data={motdData}
                maxLineWidth={maxLineWidth}
                setServerIcon={setServerIcon}
                setServerName={setServerName}
                setReloadChildren={setReloadChildren}
                reloadChildren={reloadChildren}
                fontSize={fontSize}
            />

            <MotdPreview
                data={motdData}
                serverName={serverName}
                serverIcon={serverIcon}
                setServerIcon={setServerIcon}
                maxLineWidth={maxLineWidth}
                fontSize={fontSize}
            />
            <Actions actionCallback={actionCallback} className="mt-3" />
        </div>
    );
};

export default App;
