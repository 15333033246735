import React, { useState } from "react";
import * as NBT from "nbtify";
import { NBTData } from "nbtify";
import NBTTree from "./components/NBTTree";
import pako from "pako";

const UpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
    </svg>
);

const DownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
    </svg>
);

const App = () => {
    const [isDragging, setIsDragging] = useState(false);
    const [nbtData, setNBTData] = useState<any | null>(null);
    const [rerenderKey, setRerenderKey] = useState(0);
    const [mcaMode, setMcaMode] = useState(false);
    const [searchResults, setSearchResults] = useState<string[][]>([]);
    const [currentResult, setCurrentResult] = useState(0);

    const loadNBTFile = (file: File) => {
        const reader = new FileReader();
        reader.onload = () => {
            const buffer = reader.result as ArrayBuffer;
            NBT.read(buffer)
                .then((data: NBTData) => {
                    setNBTData(data);
                    setRerenderKey((prev) => prev + 1);
                })
                .catch((e) => {
                    console.error(e);
                    setNBTData({});
                });
        };
        reader.onerror = (e) => {
            console.error(e);
            setNBTData({});
        };
        reader.readAsArrayBuffer(file);
    };

    const loadRegionFile = async (file: File) => {
        const reader = new FileReader();
        reader.onload = async () => {
            const buffer = reader.result as ArrayBuffer;
            const data = new Uint8Array(buffer);
            const locations = [];
            for (let x = 0; x < 32; x++) {
                for (let y = 0; y < 32; y++) {
                    const offsetTableOffset = 4 * ((x & 31) + (y & 31) * 32);
                    const offset = (data[offsetTableOffset] << 16) | (data[offsetTableOffset + 1] << 8) | data[offsetTableOffset + 2];
                    const sectorCount = data[offsetTableOffset + 3];
                    if (offset === 0 || sectorCount === 0) {
                        continue;
                    }

                    locations.push({
                        x: x,
                        y: y,
                        offset: offset * 4096,
                    });
                }
            }

            const chunks = [];
            for (const { x, y, offset } of locations) {
                if (offset != 2654208) continue;
                const length = (data[offset] << 24) | (data[offset + 1] << 16) | (data[offset + 2] << 8) | data[offset + 3];
                console.log(length);
                const compressionType = data[offset + 4];
                if (compressionType !== 2) {
                    alert("Unsupported compression type: " + compressionType);
                    return;
                }
                const compressedData = data.slice(offset + 5, offset + 5 + length - 1);

                // download bytes:
                const blob = new Blob([compressedData], { type: "application/octet-stream" });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "chunk_data.bin";
                a.click();

                const decompressedData = pako.inflate(compressedData);
                chunks.push({
                    data: decompressedData,
                    pos: { x, y },
                });
            }

            const nbtChunks: { [key: string]: any } = {
                data: {
                    Chunks: {},
                },
            };
            for (const { data, pos } of chunks) {
                const nbtChunk = await NBT.read(data);
                nbtChunks.data.Chunks[`Chunk at (${pos.x}, ${pos.y})`] = nbtChunk.data;
            }
            console.log(nbtChunks);
            setNBTData(nbtChunks);
        };
        reader.onerror = (e) => {
            console.error(e);
            setNBTData({});
        };
        reader.readAsArrayBuffer(file);
    };

    const handleDragOver = (e: any) => {
        if (e.dataTransfer.items.length > 0) {
            e.preventDefault();
            setIsDragging(true);
        }
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file.name.endsWith(".mca")) {
            loadRegionFile(file);
            setMcaMode(true);
        } else {
            loadNBTFile(file);
        }
    };

    const saveRegionFile = async (nbtData: any) => {
        const offsetTable = new Uint8Array(4096).fill(0);
        const timeChangedTable = new Uint8Array(4096).fill(0);
        const chunkTable = [];
        let currChunkOffset = 0;
        for (let xChunk = 0; xChunk < 32; xChunk++) {
            for (let zChunk = 0; zChunk < 32; zChunk++) {
                // Offset Table
                const offsetTableOffset = 4 * ((xChunk & 31) + (zChunk & 31) * 32);
                // Chunk Table
                const chunk = nbtData.data.Chunks[`Chunk at (${xChunk}, ${zChunk})`];
                if (!chunk) {
                    chunkTable.push(...[0, 0, 0, 0, 0]);
                    continue;
                }
                const buffer = await NBT.write(chunk);
                const compressedData = pako.deflate(buffer);
                const length = compressedData.length + 1;
                const sectorCount = Math.ceil((length + 4) / 4096);
                const data = new Uint8Array(sectorCount * 4096).fill(0);
                data[0] = (length >> 24) & 0xff;
                data[1] = (length >> 16) & 0xff;
                data[2] = (length >> 8) & 0xff;
                data[3] = length & 0xff;
                data[4] = 2;
                let binHead = "";
                for (let i = 0; i < 4; i++) {
                    binHead += data[i].toString(2).padStart(8, "0");
                }

                data.set(compressedData, 5);

                chunkTable.push(...data);

                const offset = currChunkOffset / 4096 + 2;
                offsetTable.set([(offset >> 16) & 0xff, (offset >> 8) & 0xff, offset & 0xff, sectorCount], offsetTableOffset);
                currChunkOffset += data.length;
            }
        }
        const byteChunkArr = new Uint8Array(chunkTable);
        const regionData = new Uint8Array(8192 + byteChunkArr.length);
        regionData.set(offsetTable, 0);
        regionData.set(timeChangedTable, 4096);
        regionData.set(byteChunkArr, 8192);

        const blob = new Blob([regionData], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "region_file.mca";
        a.click();
        window.document.body.style.cursor = "auto";
    };

    return (
        <div className={"relative flex flex-col" + (!nbtData ? " justify-center items-center w-full h-full" : "")}>
            {nbtData && (
                <div className="flex flex-row bg-[#0d0d0d] p-3 rounded-b-md items-center justify-between mb-5 h-16 fixed z-50 w-full">
                    <div className="flex flex-row gap-2">
                        <button
                            onClick={async () => {
                                if (mcaMode) {
                                    window.document.body.style.cursor = "wait";
                                    saveRegionFile(nbtData);
                                    return;
                                }
                                const data = await NBT.write(nbtData.data);
                                const blob = new Blob([data], { type: "application/octet-stream" });
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement("a");
                                a.href = url;
                                a.download = "nbt_file.nbt";
                                a.click();
                                URL.revokeObjectURL(url);
                            }}
                        >
                            Save
                        </button>
                        <button
                            onClick={() => {
                                const input = document.createElement("input");
                                input.onchange = () => {
                                    const file = input.files?.[0];
                                    if (!file) return;
                                    if (file.name.endsWith(".mca")) {
                                        loadRegionFile(file);
                                        setMcaMode(true);
                                    } else {
                                        loadNBTFile(file);
                                    }
                                };
                                input.type = "file";
                                input.click();
                            }}
                        >
                            Load
                        </button>
                    </div>

                    <div className="flex flex-row h-full gap-1 w-1/3">
                        <div className="flex items-center w-full rounded-md overflow-hidden ">
                            <input
                                type="text"
                                placeholder="Search..."
                                className="flex-grow p-3 outline-none"
                                onChange={(e) => {
                                    if (e.target.value === "") {
                                        setSearchResults([]);
                                        setCurrentResult(0);
                                        return;
                                    }
                                    setTimeout(() => {
                                        const paths: string[][] = [];
                                        const search = (data: any, path: string[]) => {
                                            for (const key in data) {
                                                if (
                                                    key.toLowerCase().replace(" ", "").includes(e.target.value.toLowerCase().replace(" ", "")) ||
                                                    (typeof data[key] === "string" &&
                                                        data[key]
                                                            .toString()
                                                            .toLowerCase()
                                                            .replace(" ", "")
                                                            .includes(e.target.value.toLowerCase().replace(" ", "")))
                                                ) {
                                                    paths.push([...path, key]);
                                                }
                                                if (typeof data[key] === "object") {
                                                    search(data[key], path.concat(key));
                                                }
                                            }
                                        };
                                        search(nbtData.data, []);
                                        setSearchResults(paths);
                                        setCurrentResult(0);
                                    }, 0);
                                }}
                            />
                            <div className="px-3 py-2 bg-[#1e1e1e] text-gray-600">
                                {searchResults.length != 0 && currentResult + 1 + "/"}
                                {searchResults.length}
                            </div>
                        </div>
                        <button
                            className="p-0 w-8 flex justify-center items-center"
                            disabled={searchResults.length === 0 || searchResults[currentResult] === undefined}
                            onClick={() => {
                                setCurrentResult(currentResult - 1 < 0 ? searchResults.length - 1 : currentResult - 1);
                            }}
                        >
                            <UpIcon />
                        </button>
                        <button
                            className="p-0 w-8 flex justify-center items-center"
                            disabled={searchResults.length === 0 || searchResults[currentResult] === undefined}
                            onClick={() => {
                                setCurrentResult(currentResult + 1 > searchResults.length - 1 ? 0 : currentResult + 1);
                            }}
                        >
                            <DownIcon />
                        </button>
                    </div>

                    <button onClick={() => setNBTData(null)}>Re-Upload</button>
                </div>
            )}

            {!nbtData && (
                <div
                    className={`border-2 border-slate-600 border-dashed rounded-3xl flex justify-center items-center transition-all duration-300 ${
                        isDragging ? "w-[82%] h-[82%] border-4" : "w-[80%] h-[80%]"
                    }`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <p className="text-3xl">Drop here an NBT file</p>
                </div>
            )}
            {nbtData && (
                <NBTTree
                    data={nbtData}
                    key={rerenderKey}
                    currentResult={searchResults[currentResult]}
                    onChange={(data) => {
                        console.log({ ...nbtData, data: data });
                        setNBTData({ ...nbtData, data: data });
                    }}
                />
            )}
        </div>
    );
};

export default App;
